import React, { useState } from 'react';
import { string } from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Header from './header';
import Footer from '../footer';
import Deal from './deal';
import DesktopSearch from './desktopSearch';
import MobileSearch from './mobileSearch';
import HomeGrid from '../HomeGrid';
import useConfigs from '../../hooks/useConfigs';
import LAYOUT from '../../constants/layout';
import COLORS from '../../constants/colors';
import useScrollPosition from '../../hooks/useScrollPosition';

const useStyles = makeStyles(({ spacing, zIndex, breakpoints, transitions }) => ({
  root: {
    margin: spacing(0),
  },
  header: {
    position: 'fixed',
    top: 0,
    zIndex: zIndex.appBar,
    boxShadow: COLORS.SHADOW_STANDARD,
    transition: transitions.easing.custom,
  },
  headerHidden: {
    visibility: 'hidden',
  },
  searchDesktop: {
    display: 'none',
  },
  searchMobile: {
    display: 'block',
    width: '100%',
  },
  [breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP)]: {
    searchDesktop: {
      display: 'block',
      width: '100%',
    },
    searchMobile: {
      display: 'none',
    },
  },
}));

const DealDetail = ({ slug }) => {
  const s = useStyles();
  const { breakpoints, spacing } = useTheme();
  const desktop = useMediaQuery(breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP));
  const [headerHidden, setHeaderHidden] = useState(false);

  useConfigs();

  useScrollPosition(spacing(LAYOUT.HEADER_SCROLL_TOP), setHeaderHidden);

  return (
    <HomeGrid fluid direction="column" className={s.root}>
      <HomeGrid
        fluid
        direction="column"
        justify="center"
        alignItems="center"
        className={classNames(s.header, !desktop && headerHidden && s.headerHidden)}
      >
        <Header />
        <HomeGrid className={s.searchDesktop}>
          <DesktopSearch />
        </HomeGrid>
        <HomeGrid className={s.searchMobile}>
          <MobileSearch />
        </HomeGrid>
      </HomeGrid>
      <Deal slug={slug} />
      <Footer cta />
    </HomeGrid>
  );
};

DealDetail.propTypes = {
  slug: string,
};

DealDetail.defaultProps = {
  slug: '',
};

export default DealDetail;
