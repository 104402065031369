import React, { useState, useEffect } from 'react';
import { objectOf, any } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Fade from '@material-ui/core/Fade';
import Description from './description';
import Brand from './brand';
import Branch from '../customer/branch';
import HomeGrid from '../HomeGrid';
import Category from './category';
import LAYOUT from '../../constants/layout';
import TIME from '../../constants/time';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    position: 'relative',
    margin: `${spacing(0)} auto`,
    width: '100%',
  },
  content: {
    position: 'relative',
    margin: `${spacing(0)} auto`,
    maxWidth: spacing(LAYOUT.MAX_DEAL_DETAIL_WIDTH),
  },
}));

const Detail = ({ deal }) => {
  const s = useStyles();

  const [category, setCategory] = useState('');
  const [logo, setLogo] = useState(null);
  const [branches, setBranches] = useState([]);
  const [dealId, setDealId] = useState('');

  useEffect(() => {
    setBranches(get(deal, 'branches'));
    setLogo(get(deal, 'brand.logo.url', ''));
    setCategory(get(deal, 'category.id', ''));
    setDealId(get(deal, 'id', ''));
  }, [deal]);

  return (
    <Fade timeout={TIME.EFFECT_TIMEOUT} in>
      <HomeGrid fluid direction="column" className={s.root}>
        {!isEmpty(deal) && (
          <>
            <Brand deal={deal} />
            <HomeGrid fluid direction="column" className={s.content}>
              <Description deal={deal} />
              {!isEmpty(branches) && <Branch branches={branches} logo={logo} />}
              {category && <Category category={category} mainDealId={dealId} />}
            </HomeGrid>
          </>
        )}
      </HomeGrid>
    </Fade>
  );
};

Detail.propTypes = {
  deal: objectOf(any).isRequired,
};

export default Detail;
