import React, { useEffect } from 'react';
import { string, objectOf, any, func } from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Helmet } from 'react-helmet/es/Helmet';
import { makeStyles } from '@material-ui/core/styles';
import Detail from '../deal/detail';
import HomeGrid from '../HomeGrid';
import { dealProps } from './props';
import LAYOUT from '../../constants/layout';
import META from '../../constants/meta';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    margin: spacing(0),
    background: palette.background.default,
    marginTop: spacing(LAYOUT.APP_BAR_HEIGHT),
  },
}));

const Deal = ({ slug, deal, dispatchDealDetail }) => {
  const s = useStyles();
  const { OPEN_GRAPH } = META;

  useEffect(() => {
    dispatchDealDetail(slug);
  }, [slug]);

  return (
    <HomeGrid type="section" fluid direction="column" className={s.root}>
      <Helmet>
        <meta property={OPEN_GRAPH.TITLE} content={get(deal, 'title')} />
        <meta property={OPEN_GRAPH.DESCRIPTION} content={get(deal, 'detail')} />
        <meta property={OPEN_GRAPH.IMAGE} content={get(deal, 'thumbnail')} />
      </Helmet>
      <Detail deal={deal} />
    </HomeGrid>
  );
};

Deal.propTypes = {
  slug: string.isRequired,
  deal: objectOf(any).isRequired,
  dispatchDealDetail: func.isRequired,
};

export default connect(dealProps.mapStateToProps, dealProps.mapDispatchToProps)(Deal);
