import React, { useState, useEffect } from 'react';
import { arrayOf, any, string } from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import chunk from 'lodash/chunk';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import { uuid } from 'uuidv4';
import Typo from '@material-ui/core/Typography';
import resolvedImageUrl from '../../utils/image';
import CustomImage from '../customImage';
import HomeGrid from '../HomeGrid';
import LAYOUT from '../../constants/layout';
import COLORS from '../../constants/colors';

const useStyles = makeStyles(
  ({ spacing, palette, shape, breakpoints, typography }) => ({
    root: {
      margin: `${spacing(0)} auto`,
      borderTop: `${spacing(0.125)}px solid ${COLORS.LIGHT_GREY}`,
      borderBottom: `${spacing(0.125)}px solid ${COLORS.LIGHT_GREY}`,
      background: palette.background.paper,
    },
    branches: {
      width: '100%',
      padding: spacing(4, 3),
    },
    contentLabel: {
      margin: spacing(0, 0, 2),
    },
    contentLabelTitle: {
      userSelect: 'none',
      fontSize: spacing(3),
      color: palette.text.primary,
      fontWeight: typography.fontWeightBold,
    },
    row: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      margin: spacing(1.5, 0),
      paddingBottom: spacing(3.5),
    },
    horizontal: {
      borderBottom: `${spacing(0.125)}px dashed ${COLORS.LIGHT_GREY}`,
    },
    branch: {
      width: '100%',
      display: 'flex',
      alignItems: 'flex-start',
    },
    logoWrap: {
      width: spacing(9),
      height: spacing(9),
      borderRadius: shape.borderRadius,
      overflow: 'hidden',
      marginRight: spacing(1.25),
      boxShadow: COLORS.SHADOW_STANDARD,
    },
    logo: {
      width: '100%',
      height: '100%',
    },
    info: {
      width: '80%',
    },
    title: {
      fontWeight: typography.fontWeightBold,
      marginBottom: spacing(1),
    },
    viewMore: {
      textDecoration: 'underline',
      '&:hover': {
        cursor: 'pointer',
        color: palette.text.primary,
      },
    },
    [breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP)]: {
      root: {
        maxWidth: spacing(LAYOUT.MAX_WIDTH),
        border: `0.5px solid ${COLORS.LIGHT_GREY}`,
        borderRadius: shape.borderRadius,
      },
      branches: {
        padding: spacing(4, 8),
        margin: spacing(2, 1),
      },
      row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: spacing(2, 0),
      },
      branch: {
        width: '49%',
      },
      logoWrap: {
        width: spacing(8),
        height: spacing(8),
        margin: spacing(0.5, 1.25),
      },
    },
  }),
);

const Branch = ({ branches, logo }) => {
  const s = useStyles();
  const { breakpoints } = useTheme();
  const [chunkBranches, setChunkBranches] = useState([]);
  const [displayBranches, setDisplayBranches] = useState([]);
  const [chunkIndex, setChunkIndex] = useState(0);
  const desktop = useMediaQuery(breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP));

  useEffect(() => {
    if (!isEmpty(branches)) {
      const chunks = chunk(sortBy(branches, 'name'), LAYOUT.BRANCH_PER_PAGE);
      setChunkBranches(chunks);
      setDisplayBranches(chunks[0]);
    }
  }, [branches]);

  const handleViewMore = () => {
    const index = chunkIndex + 1;
    setDisplayBranches([...displayBranches, ...chunkBranches[index]]);
    setChunkIndex(index);
  };

  const showMore = chunkIndex < chunkBranches.length - 1;
  const branchRowChunk = chunk(displayBranches, desktop ? LAYOUT.BRANCH_CHUNK : 1);

  const branchRowLen = branchRowChunk.length;

  return (
    <HomeGrid fluid className={s.root}>
      <HomeGrid className={s.branches}>
        <HomeGrid fluid>
          <HomeGrid className={s.contentLabel}>
            <Typo className={s.contentLabelTitle}>Hệ thống cửa hàng</Typo>
          </HomeGrid>
          <HomeGrid fluid direction="column">
            {branchRowChunk.map((row, index) => {
              const rowStyle =
                index < branchRowLen - 1 ? `${s.row} ${s.horizontal}` : s.row;
              return (
                <HomeGrid key={uuid()} className={rowStyle}>
                  {row.map(branch => {
                    const picture = get(branch, 'picture.url', logo);
                    const branchName = get(branch, 'name');
                    const address = get(branch, 'address');
                    return (
                      <HomeGrid key={branch.id} className={s.branch}>
                        <HomeGrid className={s.logoWrap}>
                          <CustomImage
                            logo
                            src={resolvedImageUrl(picture)}
                            alt={branch.name}
                            className={s.logo}
                          />
                        </HomeGrid>
                        <HomeGrid className={s.info}>
                          <Typo className={s.title}>{branchName}</Typo>
                          <Typo variant={desktop ? 'body1' : 'body2'}>{address}</Typo>
                        </HomeGrid>
                      </HomeGrid>
                    );
                  })}
                </HomeGrid>
              );
            })}
          </HomeGrid>
          {showMore && (
            <HomeGrid onClick={handleViewMore}>
              <Typo color="textSecondary" className={s.viewMore}>
                Xem tất cả các điểm áp dụng
              </Typo>
            </HomeGrid>
          )}
        </HomeGrid>
      </HomeGrid>
    </HomeGrid>
  );
};

Branch.propTypes = {
  branches: arrayOf(any).isRequired,
  logo: string.isRequired,
};

export default Branch;
