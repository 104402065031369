import React from 'react';
import { objectOf, any } from 'prop-types';
import get from 'lodash/get';
import DealDetail from '../components/customer/DealDetail';
import Loading from '../components/loading';
import Error from '../components/error';
import Layout from '../components/layout';
import REGEXP from '../constants/regExp';

const chitiet = ({ location }) => {
  const slug = get(location, 'pathname', '').match(REGEXP.EXTRACT_SLUG);

  return (
    <>
      <Layout>
        <DealDetail slug={get(slug, '0')} />
      </Layout>
      <Loading />
      <Error />
    </>
  );
};

chitiet.propTypes = {
  location: objectOf(any).isRequired,
};

export default chitiet;
