import React, { useEffect, useState } from 'react';
import { func, arrayOf, any, string } from 'prop-types';
import { navigate } from 'gatsby';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import chunk from 'lodash/chunk';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import DealCard from './dealcard';
import HomeGrid from '../HomeGrid';
import { categoryProps } from '../customer/props';
import LAYOUT from '../../constants/layout';
import ROUTER from '../../constants/router';
import COLORS from '../../constants/colors';
import { FILTER_KEY } from '../../constants/filters';
import { sanitizeUrl } from '../../utils/string';

const useStyles = makeStyles(({ spacing, typography, palette, breakpoints }) => ({
  root: {
    margin: `${spacing(3)}px auto`,
  },
  title: {
    fontSize: spacing(3),
    fontWeight: typography.fontWeightBold,
    margin: spacing(0, 0, 1),
  },
  slideDeal: {
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'row',
    margin: spacing(0, 1),
  },
  card: {
    display: 'inline',
  },
  icon: {
    width: spacing(4),
    height: spacing(4),
    fill: palette.text.primary,
  },
  iconActive: {
    fill: palette.text.primary,
    '&:hover': {
      fill: palette.primary.main,
      cursor: 'pointer',
    },
  },
  iconDisabled: {
    fill: COLORS.LIGHT_GREY,
    cursor: 'not-allowed',
  },
  [breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP)]: {
    root: {
      margin: `${spacing(6)}px auto`,
    },
    title: {
      fontSize: typography.h4.fontSize,
      margin: spacing(1, 0, 2),
    },
    slideDeal: {
      width: 'auto',
      minWidth: spacing(LAYOUT.MAX_WIDTH),
      margin: spacing(0, 3),
    },
  },
}));

const Category = ({ mainDealId, category, dealCategory, dispatchSameCategory }) => {
  const s = useStyles();
  const { breakpoints } = useTheme();
  const [dealList, setDealList] = useState([]);
  const [chunkIndex, setChunkIndex] = useState(0);
  const desktop = useMediaQuery(breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP));

  useEffect(() => {
    if (category) {
      dispatchSameCategory(`?${FILTER_KEY.CATEGORY}=${category}`);
    }
  }, [category]);

  useEffect(() => {
    if (!isEmpty(dealCategory)) {
      const filteredList = dealCategory.filter(item => item.id !== mainDealId);
      setDealList(
        chunk(filteredList, desktop ? LAYOUT.SAME_CATEGORY_CHUNK : filteredList.length),
      );
    }
  }, [mainDealId, dealCategory]);

  const handleViewSameDeal = value => () => {
    let navIndex;
    if (value) {
      navIndex = chunkIndex < dealList.length - 1 ? chunkIndex + 1 : chunkIndex;
    } else {
      navIndex = chunkIndex > 0 ? chunkIndex - 1 : chunkIndex;
    }
    setChunkIndex(navIndex);
  };

  const handleViewDetail = (title, id) => {
    navigate(`${ROUTER.CUSTOMER.DEAL_DETAIL}/?${sanitizeUrl(title)}?${id}`, {
      replace: true,
    });
  };

  const showSameCat = !isEmpty(dealList);
  const navLeftValid = chunkIndex > 0;
  const navRightValid = chunkIndex < dealList.length - 1;
  const navLeftStyle = navLeftValid
    ? `${s.icon} ${s.iconActive}`
    : `${s.icon} ${s.iconDisabled}`;
  const navRightStyle = navRightValid
    ? `${s.icon} ${s.iconActive}`
    : `${s.icon} ${s.iconDisabled}`;

  return (
    <HomeGrid fluid direction="column" className={s.root}>
      {showSameCat && (
        <>
          <HomeGrid fluid justify="center">
            <Typography className={s.title}>Ưu đãi cùng danh mục</Typography>
          </HomeGrid>
          <HomeGrid fluid justify="center" alignItems="center">
            {desktop && (
              <ChevronLeft
                onClick={handleViewSameDeal(false)}
                className={navLeftStyle}
              />
            )}
            <Grow
              in={showSameCat}
              style={{ transformOrigin: '0 0 0' }}
              {...(showSameCat ? { timeout: 1000 } : {})}
            >
              <HomeGrid className={s.slideDeal}>
                {dealList[chunkIndex].map(deal => (
                  <HomeGrid key={deal.id} className={s.card}>
                    <DealCard key={deal.id} onDetail={handleViewDetail} item={deal} />
                  </HomeGrid>
                ))}
              </HomeGrid>
            </Grow>
            {desktop && (
              <ChevronRight
                onClick={handleViewSameDeal(true)}
                className={navRightStyle}
              />
            )}
          </HomeGrid>
        </>
      )}
    </HomeGrid>
  );
};

Category.propTypes = {
  mainDealId: string.isRequired,
  category: string.isRequired,
  dealCategory: arrayOf(any),
  dispatchSameCategory: func.isRequired,
};

Category.defaultProps = {
  dealCategory: [],
};

export default connect(
  categoryProps.mapStateToProps,
  categoryProps.mapDispatchToProps,
)(Category);
