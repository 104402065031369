/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react';
import { objectOf, any } from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import Typo from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import BillIcon from '../../svgs/ic_bill.svg';
import DeadlineIcon from '../../svgs/ic_deadline.svg';
import OpenIcon from '../../svgs/ic_open.svg';
import OpenPageIcon from '../../svgs/ic_open_page.svg';
import CardIcon from '../../svgs/ic_card.svg';
import AppIcon from '../../svgs/ic_method-app.svg';
import InstIcon from '../../svgs/icon-installment.svg';
import CustomImage from '../customImage';
import HomeGrid from '../HomeGrid';
import { dateDeadline } from '../../utils/helpers';
import { trim } from '../../utils/string';
import resolveImageUrl from '../../utils/image';
import COLORS from '../../constants/colors';
import LAYOUT from '../../constants/layout';
import TIME from '../../constants/time';

const METHOD_ICONS = {
  card: CardIcon,
  application: AppIcon,
  installment: InstIcon,
};

const useStyles = makeStyles(
  ({ spacing, palette, typography, shape, breakpoints }) => ({
    root: {
      margin: `${spacing(0)}px auto ${spacing(2)}px`,
      background: palette.background.paper,
      userSelect: 'none',
    },
    smallLogo: {
      width: spacing(5.5),
      height: spacing(5.5),
    },
    types: {
      display: 'flex',
      alignItems: 'center',
      borderTop: `${spacing(0.125)}px solid ${COLORS.LIGHT_GREY}`,
      borderBottom: `${spacing(0.125)}px solid ${COLORS.LIGHT_GREY}`,
      width: '100%',
      padding: spacing(4, 3),
    },
    typeRow: {
      width: '100%',
    },
    brandType: {
      display: 'inline-block',
      border: `0.5px solid ${COLORS.LIGHT_GREY}`,
      background: COLORS.SMOKE,
      padding: spacing(0.25, 1),
      margin: spacing(0.5),
      fontSize: spacing(1.5),
      textTransform: 'uppercase',
      borderRadius: shape.rectRadius,
    },
    contentLabel: {
      margin: spacing(2, 0, 2),
    },
    contentLabelTitle: {
      fontSize: spacing(3),
      color: palette.text.primary,
      fontWeight: typography.fontWeightBold,
    },
    details: {
      width: '100%',
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: spacing(0),
    },
    openPage: {
      margin: spacing(0, 0.5),
    },
    detailText: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    website: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: spacing(1, 0, 0),
      color: palette.text.primary,
    },
    qrWrap: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: spacing(3, 0, 0),
      border: `1px dashed ${COLORS.LIGHT_GREY}`,
      borderRadius: shape.rectRadius,
      padding: spacing(2, 0),
    },
    qrCode: {
      width: spacing(12),
      height: spacing(12),
    },
    constraints: {
      width: '46%',
      height: spacing(18),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      margin: spacing(2, 0, 0),
      border: `1px dashed ${COLORS.LIGHT_GREY}`,
      borderRadius: shape.rectRadius,
    },
    smallConstraints: {
      display: 'flex',
      flexDirection: 'row',
    },
    iconConstraint: {
      width: spacing(6),
      height: spacing(6),
      margin: spacing(1, 0, 2),
    },
    textArea: {
      width: '100%',
      fontSize: typography.body1.fontSize,
      outline: 'none',
      '&::before': {
        borderBottom: 'none !important',
      },
    },
    textAreaInput: {
      color: palette.text.primary,
      textAlign: 'justify',
    },
    textAreaWrap: {
      width: '100%',
    },
    textMethods: {
      userSelect: 'none',
    },
    [breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP)]: {
      root: {
        maxWidth: spacing(LAYOUT.MAX_WIDTH),
        margin: `${spacing(2)}px auto`,
        borderRadius: shape.borderRadius,
      },
      types: {
        border: `0.5px solid ${COLORS.LIGHT_GREY}`,
        borderRadius: shape.rectRadius,
        padding: spacing(6, 8),
      },
      typeRow: {
        display: 'flex',
        flexDirection: 'row',
      },
      brandType: {
        margin: spacing(0, 0.5),
      },
      contentLabel: {
        margin: spacing(4, 0, 3),
      },
      details: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: `${spacing(0.125)}px dashed ${COLORS.LIGHT_GREY}`,
        paddingBottom: spacing(3.5),
        marginBottom: spacing(3),
      },
      detailText: {
        width: '80%',
      },
      website: {
        margin: spacing(2, 0, 0),
      },
      qrWrap: {
        width: 'auto',
        margin: 'unset',
        border: 'unset',
        padding: 'unset',
      },
      constraints: {
        width: '20%',
        alignItems: 'flex-start',
        textAlign: 'left',
        margin: 'unset',
        border: 'unset',
        height: 'auto',
      },
    },
  }),
);

const Description = ({ deal }) => {
  const s = useStyles();
  const { breakpoints } = useTheme();
  const desktop = useMediaQuery(breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP));
  const dealTitle = get(deal, 'title', '');
  const brandName = get(deal, 'brand.name');
  const website = get(deal, 'brand.website');
  const types = get(deal, 'types', []);
  const detail = get(deal, 'detail', '');
  const qrCode = get(deal, 'qrCode.url');
  const open = get(deal, 'open');
  const bill = get(deal, 'bill');
  const methods = get(deal, 'methods');
  const expire = get(deal, 'expire');
  const showExpire = moment(expire).diff(moment(), 'd') < TIME.EXPIRED_WARN;
  const TIME_LINE = [
    { id: 0, icon: OpenIcon, text: open, active: true },
    {
      id: 1,
      icon: DeadlineIcon,
      text: `Hết hạn: ${moment(expire).format(TIME.DATE.FORMAT)}`,
      active: true,
      warning: showExpire ? `${dateDeadline(expire)}` : '',
    },
    { id: 2, icon: BillIcon, text: 'Áp dụng cho tất cả các hóa đơn', active: bill },
  ];
  const METHOD_DISPLAY = [
    ...methods.map((item, index) => ({
      id: index + 2,
      icon: METHOD_ICONS[item.icon],
      text: item.name,
      active: true,
    })),
  ];
  const methodsCount = methods.length;

  return (
    <HomeGrid fluid justify="space-between" alignItems="center" className={s.root}>
      <HomeGrid className={s.types}>
        <HomeGrid fluid direction="column">
          <HomeGrid className={s.typeRow}>
            {!isEmpty(types) &&
              types.map(type => (
                <HomeGrid component="div" key={type.id} className={s.brandType}>
                  {type.name}
                </HomeGrid>
              ))}
          </HomeGrid>
          <HomeGrid className={s.contentLabel}>
            <Typo className={s.contentLabelTitle}>Chi tiết ưu đãi</Typo>
          </HomeGrid>
          <HomeGrid className={s.details}>
            <HomeGrid fluid justify="space-between" className={s.detailText}>
              <TextField
                multiline
                disabled
                className={s.textAreaWrap}
                inputProps={{
                  className: s.textAreaInput,
                }}
                InputProps={{
                  className: s.textArea,
                }}
                value={detail}
              />
              {website && (
                <a
                  href={website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={s.website}
                >
                  <Typo>Xem thêm trên trang {brandName}</Typo>
                  <OpenPageIcon className={`${s.icon} ${s.openPage}`} />
                </a>
              )}
            </HomeGrid>
            {qrCode && (
              <HomeGrid className={s.qrWrap}>
                <CustomImage
                  logo
                  src={resolveImageUrl(qrCode)}
                  alt={dealTitle}
                  className={s.qrCode}
                />
              </HomeGrid>
            )}
          </HomeGrid>
          <HomeGrid>
            <HomeGrid fluid justify="space-between">
              {TIME_LINE.map(item => (
                <HomeGrid key={item.id} className={s.constraints}>
                  <HomeGrid className={s.iconConstraint}>
                    <item.icon />
                  </HomeGrid>
                  <Typo variant={desktop ? 'body1' : 'body2'}>
                    {item.active
                      ? `${trim(item.text, LAYOUT.CARD.TITLE_LENGTH_LARGE)}`
                      : `Không ${item.text.toLowerCase()}`}
                  </Typo>
                  {item.warning && (
                    <Typo variant={desktop ? 'body1' : 'body2'} color="error">
                      {item.warning}
                    </Typo>
                  )}
                </HomeGrid>
              ))}
              {methodsCount === 1 &&
                METHOD_DISPLAY.map(item => (
                  <HomeGrid key={item.id} className={s.constraints}>
                    <HomeGrid className={s.iconConstraint}>
                      <item.icon />
                    </HomeGrid>
                    <Typo variant={desktop ? 'body1' : 'body2'}>{item.text}</Typo>
                  </HomeGrid>
                ))}
              {methodsCount > 1 && (
                <HomeGrid className={s.constraints}>
                  <HomeGrid className={s.smallConstraints}>
                    {METHOD_DISPLAY.map(item => (
                      <HomeGrid key={item.text} className={s.iconConstraint}>
                        <Tooltip
                          placement={!desktop ? 'right' : 'bottom'}
                          title={item.text}
                        >
                          <Typo>
                            <item.icon className={s.smallLogo} />
                          </Typo>
                        </Tooltip>
                      </HomeGrid>
                    ))}
                  </HomeGrid>
                  <HomeGrid>
                    <Typo
                      variant={desktop ? 'body1' : 'body2'}
                      className={s.textMethods}
                    >
                      Phương thức áp dụng
                    </Typo>
                  </HomeGrid>
                </HomeGrid>
              )}
            </HomeGrid>
          </HomeGrid>
        </HomeGrid>
      </HomeGrid>
    </HomeGrid>
  );
};

Description.propTypes = {
  deal: objectOf(any).isRequired,
};

export default Description;
